.main-view-of-2nd-screen {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
}

.wish-zone {
  position: absolute;
  width: 60%;
  height: 66%;
  display: flex;
  background-color: #fff;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -1px 40px -10px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 0px -1px 40px -10px rgba(0, 0, 0, 0.65);
  box-shadow: 0px -1px 40px -10px rgba(0, 0, 0, 0.65);
  transform: rotate(-5deg) scale(0);
}

.wish-logo {
  height: 300px
}

.wish-zone-anim {
  animation: show-wish-zone 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

.wish-quote-image-container {
  width: 100%;
  justify-content: center;
}

.wish-quote-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wish-quote {
  color: red;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Pacifico', cursive;
  font-size: 21px;
}

.blossom-top-left {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 150px;
}

.blossom-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0
}

.from-author {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
  font-size: 18px;
  font-family: 'Pacifico', cursive;
}

.click-to-open-gift-text {
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'Pacifico', cursive;
}

.wrap-gift-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.main-gift-box, .lid-gift-box {
  align-self: center;
}

.lid-gift-box__lid, .main-gift-box__main {
  max-width: 260px;
}

.lid-gift-box__lid {
  animation: 1s wiggle infinite linear;
  animation-fill-mode: forwards;
}

.wrap-text-arrow {
  width: max-content;
  margin-bottom: 20px;
}

.rotating-down {
  animation: 1s rotating-main-box;
  animation-fill-mode: forwards;
}

.rotating-arrows {
  animation: 1s rotating-arrow-hidden;
  animation-fill-mode: forwards;
}

.rotating {
  animation: 1s rotating;
  animation-fill-mode: forwards;
}

.arrows {
  position: relative;
  top: 50%;
  left: 57%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
}

.arrows:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
  border-bottom: 5px solid rgba(0, 0, 0, 0.7);
  transform: translate(26.66667px, 20px) rotate(-45deg);
  animation: arrows 1.5s linear infinite;
  animation-fill-mode: forwards;
}

.arrows:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
  border-bottom: 5px solid rgba(0, 0, 0, 0.7);
  transform: translate(10px, 0px) rotate(-45deg);
  animation: arrows 1.5s linear infinite -0.75s;
  animation-fill-mode: forwards;
}

.wrap-text-arrow-giftbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  overflow: hidden;
}

.animal-of-year {
  max-height: 60px; 
  max-width: 60px;
  margin-right: 10px
}

@keyframes show-wish-zone {
  0% {
    opacity: 0;
    transform: rotate(-5deg) scale(0);
  }

  50% {
    transform: rotate(-5deg) scale(1.1);
  }

  100% {
    opacity: 1;
    transform: rotate(-5deg) scale(1);
  }
}

@keyframes wiggle {
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(3px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-5px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(5px, 0, 0);
  }
}

@keyframes rotating-main-box {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(300px);
    opacity: 0;
    display: none;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) translate(15%, 0);
  }
  100% {
    transform: rotate(145deg) translate(-100%, -250px);
    opacity: 0;
    display: none;
  }
}

@keyframes rotating-arrow-hidden {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes arrows {
  0% {
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: translate(-13.33333px, -23.33333px) rotate(-45deg);
  }
  10%, 90% {
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
  50% {
    border-left: 5px solid rgba(0, 0, 0, 0.7);
    border-bottom: 5px solid rgba(0, 0, 0, 0.7);
    transform: translate(-13.33333px, 0px) rotate(-45deg);
  }
  100% {
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: translate(-13.33333px, 23.33333px) rotate(-45deg);
  }
}

@media only screen and (max-width: 990px) {
  /* For everything bigger than 990px */
  .wish-quote {
    margin-top: 15px;
    font-size: 18px;
  }

  .from-author {
    font-size: 15px;
  }

  .wish-logo {
    height: 230px
  }
}

@media only screen and (max-width: 650px) {
  .blossom-bottom-right {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .wish-quote {
    font-size: 16px;
  }

  .from-author {
    font-size: 12px;
  }

  .blossom-top-left {
    max-height: 90px;
  }

  .wish-logo {
    height: 180px
  }

  .animal-of-year {
    max-height: 40px; 
    max-width: 40px;
  }

  .wish-zone {
    width: 73%;
    height: 77%;
  }
}