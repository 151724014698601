.error-404, .error-422, .error-500 {
  padding: 40px 15px;
  text-align: center;

  h1 {
    font-size: 100px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  h2 {
    font-size: 3rem;
  }

  .error-details {
    font-size: 17px;
  }

  .back-to-home {
    margin-bottom: 10px;
    padding: 5px 12px;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 5px;
    transition: .5s;
    color: var(--titleNav-color);
    border: 1px solid var(--titleNav-color)!important;

    &:hover {
    background-color: var(--titleNav-color);
    color: var(--white-color);
    }

    span {
      font-size: 20px;
    }
  }
}
