.main-view {
  min-height: 100vh;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}

.new-year-logo {
  height: 700px;
  animation: logo 2.5s;
}

.cloud-top-container {
  position: absolute;
  top: 0;
  background-image: url('../../../../assets/images/new_year/cloud.png');
  background-position: top;
  background-size: cover;
  height: 300px;
  width: 100%;
}

@keyframes logo {
  from {
    opacity: 0;
    height: 900px;
  }

  to {
    opacity: 1;
    height: 700px;
  }
}

@keyframes logo-responsive {
  from {
    opacity: 0;
    height: 500px;
  }

  to {
    opacity: 1;
    height: 350px;
  }
}

@media only screen and (max-width: 450px) {
  .new-year-logo {
    height: 350px;
    animation: logo-responsive 2.5s;
  }
}
