.main-view {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/images/new_year/pure_bgr.png");
  background-attachment: fixed;
  background-size: 100% 100%;
}

.gift-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/images/new_year/pagodas.png');
  background-position: bottom;
  background-repeat: no-repeat;
}

.tap-to-open-main-view {
  background-image: url("../../assets/images/new_year/app_background.jpg");
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
}

.tap-to-open-main-view-with-anim {
  animation: tap-to-open-main-view-anim 0.7s;
  animation-fill-mode: forwards;
}

.click-to-play {
  font-family: 'Pacifico', cursive;
  font-size: 22px;
  text-align: center;
  margin-top: 15px;
  margin-left: 35px;
  margin-right: 35px;
}

@keyframes tap-to-open-main-view-anim {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(1.2);
  }
}
